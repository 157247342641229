<template>
  <div style="margin: 80px">
    <h1><b>ข้อกำหนดการใช้บริการ</b></h1>
    <h6>กรุณาอ่านข้อกำหนดการใช้บริการแล้วยอมรับ</h6>
    <b-card id="card">
      <div id="body">
        เงื่อนไขการใช้บริการ <br /><br />

        เงื่อนไขการใช้บริการฉบับนี้ มีวัตถุประสงค์เพื่อแจ้งถึงสิทธิ
        และหน้าที่ตามกฎหมายในส่วนที่เกี่ยวข้องกับการให้บริการ ระหว่าง “เรา”
        “บริษัท โซซิโอ จำกัด” ในฐานะ “ผู้ให้บริการ” และ “ผู้ใช้บริการ” ในฐานะ
        “ผู้ใช้บริการ” ดังนั้น
        โปรดอ่านเงื่อนไขการใช้บริการโดยละเอียดเพื่อประโยชน์ในการรับบริการจากเรา
        <br /><br /><b> การตกลงยอมรับเงื่อนไขการใช้บริการ</b>
        การตกลงสมัครเข้าใช้บริการทุกประเภทของ ผู้ให้บริการ นั้นถือว่า
        ผู้ใช้บริการยอมรับว่า
        ได้อ่านและทำความเข้าใจเงื่อนไขการใช้บริการฉบับนี้โดยละเอียดถี่ถ้วนแล้ว
        และผู้ใช้บริการยอมรับเงื่อนไขการใช้บริการทุกประการ
        เงื่อนไขฉบับนี้มีผลผูก พันผู้ใช้บริการตามกฎหมาย <br />
        <br /><b>ขอบเขตการบังคับใช้ของเงื่อนไขการใช้บริการ</b>
        เงื่อนไขการใช้บริการฉบับนี้ใช้กับการให้บริการโดยตรงโดยผู้ให้บริการ
        ให้แก่ผู้ใช้บริการเท่านั้น
        ไม่มีผลบังคับใช้กับการให้บริการโดยบุคคลหรือนิติบุคคลอื่นที่อาจเชื่อมโยงจากหน้าเว็บไซต์ของบริษัท
        หรือที่อาจเชื่อมโยงต่อเนื่องจากบริการของผู้ให้บริการ
        ซึ่งรวมถึงแต่ไม่จำกัดเพียง
        ผู้ให้บริการการชำระเงินสำหรับบริการจ่ายเงินเดือน ผู้ให้บริการ E-Tax
        Invoice ผู้ให้บริการแพลตฟอร์มอีคอมเมิร์ซ
        ผู้ให้บริการตัวกลางรับและจ่ายชำระเงิน ผู้ให้บริการระบบขายหน้าร้าน ได้
        เนื่องจากการให้บริการของบุคคลดังกล่าวไม่อยู่ในวิสัยที่ผู้ให้บริการจะสามารถควบคุมหรือรับประกันได้
        ดังนั้นในการติดต่อใช้บริการระหว่างผู้ใช้บริการ
        และผู้ให้บริการภายนอกดังกล่าวเป็นส่วนที่ผู้ใช้บริการ
        ต้องศึกษาและทำความเข้าใจเงื่อนไขการใช้บริการของผู้ให้บริการภายนอกดังกล่าวแยกต่างหากจากเงื่อนไขฉบับนี้<br /><br />
        ทั้งนี้นอกเหนือจากเงื่อนไขการใช้บริการ ผู้ใช้บริการยอมรับว่า
        ผู้ให้บริการมีความจำเป็นต้องใช้ข้อมูลของผู้ใช้บริการที่ได้ให้ไว้แก่ผู้ให้บริการผ่านทางเว็บไซต์หรือผ่านช่องทางอื่น
        เพื่อการปฏิบัติสิทธิและหน้าที่ของผู้ให้บริการ
        โดยผู้ให้บริการจะดำเนินการดังกล่าวโดยสอดคล้องกับประกาศความเป็นส่วนตัว
        (Privacy Notice) สำหรับลูกค้าและผู้รับบริการ

        <br />
        <br /><br /><b>1. คำจำกัดความ</b>

        <br />“ข้อตกลง” หมายความถึง เงื่อนไขการใช้บริการ, สัญญา

        <br />“คู่สัญญา” หมายความถึง บริษัท โซซิโอ จำกัด และ
        ผู้ใช้บริการที่สมัครเข้าใช้บริการผ่านทางเว็บไซต์

        <br />“ค่าบริการ” หมายความถึง ค่าบริการรายเดือนและรายปี
        ที่ผู้ใช้บริการมีหน้าที่ต้องชำระตามรอบที่กำหนดตามสัญญา
        ตามอัตราที่ปรากฏบนหน้าเว็บไซต์

        <br />“ข้อมูล” หมายความถึง ข้อมูลใดๆที่ผู้ใช้บริการ
        หรือผู้ที่ได้รับมอบอำนาจจากผู้ใช้บริการให้ทำการแทนได้ให้ไว้แก่
        ผู้ให้บริการผ่านทางเว็บไซต์ หรือช่องทางการติดต่อสื่อสารอื่นใด

        <br />“ข้อมูลลับ” หมายความถึง ข้อมูลต่างๆ ที่คู่สัญญาได้ให้ไว้แก่กัน
        ไม่ว่าจะโดยทางวาจา, ลายลักษณ์อักษร หรือข้อมูลอิเลคทรอนิคส์
        แต่ไม่รวมถึงข้อมูลใดๆที่ได้ถูกเผยแพร่สู่สาธารณชนเป็นที่เรียบร้อยแล้ว

        <br />“สิทธิในทางทรัพย์สินทางปัญญา” หมายความถึง สิทธิบัตร,
        เครื่องหมายการค้า, เครื่องหมายบริการ, ลิขสิทธิ, สิทธิโดยธรรม,
        สิทธิในแบบผลิตภัณฑ์
        ไม่ว่าจะได้จดทะเบียนไว้ภายใต้กฎหมายประเทศไทยหรือประเทศใดหรือไม่ก็ตาม

        <br />“การให้บริการ” หมายความถึง
        การให้บริการซอฟต์แวร์สำหรับการจัดการระบบ Wi-Fi ออนไลน์ (ผู้ให้บริการ)
        รวมถึงบริการอื่นที่ผู้ให้บริการอาจให้แก่ ผู้ใช้บริการผ่านทางเว็บไซต์

        <br />“ผู้ใช้งานในระบบ” หมายความถึง บุคคล หรือบริษัทอื่นใด
        ที่มิใช่ผู้ใช้บริการที่เป็นคู่สัญญากับ ผู้ให้บริการ
        แต่ได้รับมอบอำนาจจากผู้ใช้บริการให้สามารถเข้าใช้บริการในนามของผู้ใช้บริการดังกล่าว
        ทั้งนี้เมื่อผู้ใช้บริการให้ข้อมูลของผู้ใช้งานในระบบแก่ ผู้ให้บริการ
        ผู้ให้บริการจะถือว่า ผู้ใช้บริการรับประกันและรับรองความถูกต้อง ครบถ้วน
        และเหมาะสมในการมอบอำนาจโดยผู้ใช้ บริการ ให้แก่ ผู้ใช้งานในระบบทันที
        และการดำเนินการใดโดยผู้ใช้งานในระบบจะมีผลผูกพันผู้ใช้บริการที่เกี่ยวข้องทั้งหมด

        <br />“ผู้ใช้บริการ” หมายความถึง ผู้ใช้บริการผู้เป็นคู่สัญญากับ
        ผู้ให้บริการ ไม่รวมถึง ผู้ใช้งานในระบบ

        <br />“ผู้ให้บริการ” หมายความถึง บริษัท โซซิโอ จำกัด

        <br />“เว็บไซต์” หมายความถึง socio.co.th

        <br />“เหตุสุดวิสัย” หมายความถึง เหตุใด ๆ อันจะเกิดขึ้นก็ดี
        จะให้ผลพิบัติก็ดี
        เป็นเหตุที่ไม่อาจป้องกันได้แม้ทั้งบุคคลผู้ต้องประสบหรือใกล้จะต้องประสบเหตุนั้น
        จะได้จัดการระมัดระวังตามสมควรอันพึงคาดหมายได้จากบุคคลในฐานะและภาวะเช่นนั้น

        <br />“สำนักงานบัญชี” หมายถึง สำนักงานบัญชี
        หรือนักบัญชีอิสระที่อาจลงทะเบียนเพื่อเป็น Partner
        ในการให้บริการแก่ผู้ใช้บริการ ผ่านระบบของผู้ให้บริการ

        <br /><br /><b>2. การให้บริการ</b>

        <br /><b>2.1 แพ็กเกจการให้บริการ</b> การให้บริการโดยผู้ให้บริการแก่
        ผู้ใช้บริการ จะเป็นไปตามเงื่อนไขที่กำหนดไว้สำหรับแพ็กเกจ
        และรูปแบบการให้บริการตาม ที่ปรากฏบนหน้าเว็บไซต์
        ซึ่งผู้ใช้บริการได้เลือกใช้บริการกับทางผู้ให้บริการ

        <br /><b>2.2 การเปลี่ยนแปลงแพ็กเกจการให้บริการ</b>
        ผู้ให้บริการสงวนวสิทธิในการเปลี่ยนแปลงการให้บริการ
        รวมถึงแต่ไม่จำกัดเพียงแพ็กเกจการให้บริการหรือราคาค่าบริการได้ตลอดเวลา
        เพื่อให้สอดคล้องกับความต้องการของผู้ใช้บริการและตามสภาพสถานการณ์ที่เปลี่ยนแปลงไป
        โดยถือว่า การเปลี่ยนแปลงมีผลทันทีเมื่อทำการแก้ไขบนหน้าเว็บไซต์
        ทั้งนี้ผู้ให้บริการจะแจ้งให้ผู้ใช้บริการทุกผู้ใช้บริการทราบถึงการเปลี่ยนแปลงผ่านช่องทางอีเมล
        หรือหน้าเว็บไซต์

        <br />ดังนั้นแนะนำให้ผู้ใช้บริการอ่านและทำความเข้าใจเงื่อนไขการบริการล่าสุดที่ปรากฎบนหน้าเว็บไซต์
        และการที่ผู้ใช้บริการยังคงใช้บริการของผู้ให้บริการอยู่
        ภายหลังการแก้ไขเปลี่ยนแปลงดังกล่าว ผู้ให้บริการจะถือว่า
        ผู้ใช้บริการตกลงยอมรับการเปลี่ยนแปลงดังกล่าวทันที

        <br /><b>2.3 สิทธิการใช้บริการ</b> ผู้ให้บริการให้สิทธิแก่ผู้ใช้บริการ
        ในการเข้าถึงและใช้บริการในแพ็กเกจต่าง ๆ ของผู้ให้บริการ
        ตามที่ผู้ใช้บริการได้เลือกไว้
        โดยสิทธิดังกล่าวนี้มิใช่สิทธิแต่เพียงผู้เดียว (non-exclusive)
        ไม่สามารถโอนได้
        และการใช้สิทธิดังกล่าวต้องอยู่ภายใต้เงื่อนไขการใช้บริการฉบับนี้

        <br /><b>2.4 ข้อจำกัดการให้บริการ</b>
        ผู้ให้บริการให้บริการซอฟต์แวร์เพื่อให้ความสะดวกสบายในการจัดการระบบ Wi-Fi
        ของผู้ใช้บริการเท่านั้น
        ในการให้บริการของผู้ให้บริการจึงอยู่ภายใต้ข้อจำกัด ดังนี้

        <br />ผู้ให้บริการไม่อาจรับประกันความแม่นยำในการให้บริการด้วยเทคโนโลยีสำหรับการใช้งานทุกรูปแบบ
        ด้วยข้อจำกัดทางด้านเทคโนโลยี
        อาจทำให้การให้บริการของผู้ให้บริการอยู่ภายใต้ข้อจำกัดบางส่วน เช่น
        browser version หรือข้อจำกัดทางด้านอุปกรณ์ที่ใช้ในการเชื่อมต่อ
        ซึ่งอาจมีความแตกต่างกันสำหรับผู้ใช้บริการแต่ละบุคคล แม้ว่าผู้ให้
        บริการจะใช้ความพยายามอย่างดีที่สุด
        ในการทำให้ซอฟต์แวร์ที่สามารถเชื่อมโยงและทำงานได้เต็มประสิทธิภาพกับ
        ทุกอุปกรณ์ แต่ด้วยข้อจำกัดทางด้านเทคโนโลยีบางส่วน การให้บริการบางส่วน
        อาจไม่สามารถดำเนินการได้แม่นยำทั้งหมด ดังนั้นผู้ให้บริการแนะนำให้ผู้ใช้
        บริการตรวจสอบความถูกต้องของข้อมูลที่จะนำเข้าระบบทั้งหมดอีกครั้ง
        เพื่อหลีกเลี่ยงข้อสงสัย
        ผู้ขอใช้บริการสามารถที่จะเข้ามาใช้บริการเพื่อทำธุรกรรมต่าง ๆ
        บนเว็บไซต์ผ่านทางเครือ ข่ายอินเตอร์เน็ตได้ตลอด 24 ชั่วโมง
        โดยไม่มีวันหยุด ยกเว้นในกรณีที่ระบบคอมพิวเตอร์
        อุปกรณ์หรือระบบสื่อสารที่เกี่ยวข้องของผู้ให้บริการได้รับความเสียหาย
        หรืออยู่ในช่วงกำลังปรับปรุงหรือซ่อมแซม หรือเกิดเหตุสุดวิสัย
        ซึ่งทางผู้ให้บริการขอสงวนสิทธิ์ในการหยุดให้บริการ
        เพื่อแก้ไขปรับปรุงซ่อมแซมเว็บไซต์ โดยไม่ต้องแจ้งให้ทราบล่วง
        หน้าและไม่ถือว่าเป็นความผิดของผู้ให้บริการ
        ทั้งนี้ผู้ให้บริการจะไม่รับผิดชอบต่อความเสียหายอันเกิดจากเหตุสุด วิสัย
        หรือการแก้ไขปรับปรุงซ่อมแซมเว็บไซต์จากเหตุดังกล่าว

        <br /><br /><b>3. การสมัครใช้บริการ การแก้ไขเปลี่ยนแปลง การต่อสัญญา</b>

        <br /><b>3.1 การสมัครใช้บริการ</b>
        การสมัครใช้บริการนั้นสามารถทำได้โดยส่งคำขอใช้บริการผ่านทางหน้าเว็บไซต์
        ทั้งนี้ผู้ให้บริการสงวนสิทธิในการปฏิเสธคำขอใช้บริการใด
        ที่ไม่ผ่านหลักเกณฑ์การประเมินหรือเงื่อนไขที่กำหนดไว้ด้วยดุลพินิจฝ่ายเดียวของผู้ให้
        บริการ
        ซึ่งผู้ให้บริการไม่มีหน้าที่ที่ต้องเปิดเผยหลักเกณฑ์การประเมินดังกล่าวให้ผู้ใช้บริการทราบก่อน

        <br /><b>3.2 อายุสัญญา และการต่อสัญญา</b>
        ระยะเวลาในการใช้บริการแพ็กเกจการให้บริการประเภทมีค่าบริการของผู้ให้บริการนั้น
        มีระยะเวลาขั้นต้นหนึ่งปี เว้นแต่จะกำหนดไว้เป็นอย่างอื่นบนหน้าเว็บไซต์

        <br />ทั้งนี้
        หากผู้ใช้บริการไม่แสดงเจตนาเลิกสัญญาตามวิธีการที่ผู้ให้บริการอาจประกาศกำหนดภายใต้เงื่อนไขฉบับนี้
        30 วันก่อนสิ้นสุดระยะเวลาในการใช้บริการในรอบปีดังกล่าว
        ระยะเวลาในการใช้บริการจะขยายออกไปโดยอัตโนมัติอีกหนึ่งปี
        และจะขยายออกไปโดยอัตโนมัติคราวละหนึ่งปีด้วยวิธีการเดียวกัน
        ทั้งนี้เพื่อหลีกเลี่ยงข้อสงสัยผู้ให้บริการจะดำเนินการเรียกชำระ
        ค่าบริการสำหรับระยะเวลาที่ได้รับการต่ออายุดังกล่าวในทันที

        <br /><b>3.3 บัญชีผู้ใช้งาน</b> ผู้ใช้บริการต้องเก็บรักษาบัญชีผู้ใช้งาน
        (User Account) และรหัสผ่าน (Password)
        ของผู้ใช้บริการไว้ด้วยความระมัดระวังและเป็นความลับ
        โดยผู้ใช้บริการจะเป็นฝ่ายรับความเสี่ยงเพียงฝ่ายเดียว
        ในกรณีที่บุคคลภายนอกใช้รหัสผ่านโดยฉ้อฉลหรือโดยมิได้รับอนุญาต

        <br />ทั้งนี้ ผู้ให้บริการจะถือว่า
        การใช้รหัสผ่านเพื่อการดำเนินการใดก็ตาม เพื่อการใช้งานผ่านบัญชีผู้ใช้งาน
        (User Account) ของผู้ใช้บริการ
        เป็นการกระทำของผู้ใช้บริการในฐานะคู่สัญญาที่ถือรหัสผ่านดังกล่าว
        ในกรณีที่มีการใช้บัญชีผู้ใช้งานของผู้ใช้
        บริการโดยบุคคลภายนอกที่ปราศจากอำนาจ
        ผู้ใช้บริการมีหน้าที่ที่จะต้องแจ้งเหตุดังกล่าวให้ผู้ให้บริการทราบโดยทันที
        เพื่อ
        ที่ทางผู้ให้บริการจะได้ใช้มาตราการเพื่อทำการยับยั้งและแก้ไขปัญหาดังกล่าวได้อย่างทันท่วงที
        ทังนี้มาตรการดังกล่าวมีขึ้นเพื่อให้ความช่วยเหลือแก่ผู้ใช้บริการเท่านั้น
        ผู้ให้บริการไม่มีความรับผิดใดต่อผู้ใช้บริการหรือผู้อื่นในความบกพร่องหรือผลใดของการใช้มาตรการเช่นว่า

        <br /><b>3.4 การแต่งตั้งผู้ใช้งานระบบ</b>
        ผู้ใช้บริการมีสิทธิมอบหมายให้บุคคลหรือบริษัทใด
        เป็นผู้ใช้งานในระบบเพื่อเข้าจัดการบัญชีของผู้ใช้บริการดังกล่าวได้
        ตามจำนวนที่สอดคล้องกับแพ็กเกจการให้บริการที่เลือก
        โดยผู้ใช้บริการจะต้องรับผิดชอบต่อการใด
        ที่ผู้ใช้งานในระบบได้กระทำลงเสมือนกับเป็นการกระทำของผู้ใช้บริการเอง
        ไม่ว่าการกระทำดังกล่าวจะอยู่ในความรับรู้ของผู้ใช้บริการหรือไม่ก็ตาม
        เพื่อหลีกเลี่ยงข้อสงสัย
        หากการปฏิบัติของผู้ใช้งานในระบบก่อให้เกิดความเสียหายหรือการเสียผลประโยชน์แก่ผู้ใช้บริการ
        ทางผู้ให้บริการจะไม่รับผิดชอบใดทั้งสิ้น

        <br /><b>3.5 การแก้ไขสิทธิในการใช้บริการ</b>
        ผู้ใช้บริการมีสิทธิเด็ดขาดในการเปลี่ยนแปลง จำกัด
        หรือยกเลิกการใช้งานของผู้ใช้งานในระบบได้ตามที่ผู้ใช้บริการเห็นสมควร
        ในกรณีที่มีข้อพิพาทเกิดขึ้นระหว่างผู้ใช้บริการและผู้ใช้งานในระบบ
        ผู้ให้บริการจะปฏิบัติตามคำร้องขอหรือคำสั่งของผู้ใช้บริการแต่เพียงผู้เดียวในฐานะคู่สัญญา
        ดังนั้นผู้ให้บริการขอสงวนสิทธิในการไม่ปฏิบัติตามคำร้องขอหรือคำสั่งใดจากบุคคลภายนอก
        ไม่ว่าจะเป็น ผู้ใช้งานในระบบ หรือ ตัวแทนผู้รับมอบอำนาจของผู้ใช้บริการ
        ที่มิใช่คู่สัญญากับผู้ให้บริการ เว้นแต่ จะปรากฏว่า
        ผู้ใช้บริการได้ให้ความยินยอมชัดแจ้งแก่ทางผู้ให้บริการเป็นลายลักษณ์อักษร
        เพื่อดำเนินการดังกล่าว

        <br /><br /><b>4. การชำระค่าบริการ</b>

        <br /><b>4.1 การใช้บริการแพ็กเกจฟรี</b> ผู้ใช้บริการที่เลือกแพ็กเกจฟรี
        รับทราบว่า
        การใช้บริการของผู้ใช้บริการดังกล่าวจะอยู่ภายใต้ข้อจำกัดที่ระบุไว้ในหน้าเว็บไซต์โดยผู้ให้บริการ

        <br /><b>4.2 การทดลองใช้ฟรีสำหรับการใช้แพ็กเกจ (Trial)</b>
        เพื่อให้ผู้ใช้บริการได้ทดลองใช้การให้บริการของผู้ให้บริการในรูปแบบการชำระราคา
        ผู้ใช้บริการสามารถเลือกทดลองใช้แพ็กเกจดังกล่าวได้
        โดยมีเงื่อนไขจำกัดในส่วนของระยะเวลา
        ซึ่งผู้ใช้บริการต้องตัดสินใจที่จะซื้อแพ็กเกจที่ทดลองในอัตราที่ผู้ให้บริการกำหนดไว้ผ่านเว็บไซต์
        ภายในระยะเวลาก่อนที่ระยะเวลาการทดลองใช้จะสิ้นสุดลง
        เพื่อเก็บรักษาข้อมูลทั้งหมดที่ได้มีการดำเนินการระหว่างระยะเวลาทดลองใช้ดังกล่าว
        หากผู้ใช้บริการ ไม่ได้ดำเนินการชำระค่าบริการสำหรับแพ็กเกจดังกล่าว
        ภายในระยะเวลาดังกล่าว ผู้ให้บริการสงวนสิทธิที่จะไม่เก็บรักษา
        ข้อมูลที่ผู้ใช้บริการได้ให้ไว้ระหว่างระยะเวลาทดลองใช้ดังกล่าวไว้
        โดยไม่ต้องรับผิดชอบสำหรับความเสียหายที่อาจเกิดขึ้นกับผู้ใช้บริการในกรณีดังกล่าว

        <br /><b>4.3 การชำระค่าบริการ:</b>
        ผู้ใช้บริการมีหน้าที่ชำระค่าบริการรายปีหรือตามอัตราที่กำหนดไว้ในแพ็กเกจที่เลือกใช้บริการ
        โดยผู้ให้บริการจะทำการเรียกเก็บเงินในทุกรอบปีหรือตามอัตราที่กำหนดไว้
        จนกว่าจะมีการเลิกสัญญาโดยสอดคล้องกับเงื่อนไขฉบับนี้
        ทั้งนี้การเรียกชำระค่าบริการดังกล่าวจะอยู่ภายใต้เงื่อนไขดังนี้

        <br />4.3.1 รูปแบบการชำระ
        ผู้ให้บริการจะเรียกเก็บค่าบริการเป็นสกุลเงินบาท
        โดยอาจรวมภาษีอากรต่างๆที่เกี่ยวข้อง (ถ้ามี)
        ตามที่ได้มีการกำหนดและแจ้งให้ผู้ใช้บริการทราบผ่านเว็บไซต์
        โดยการตัดบัตรเดรดิต หรือการโอนเงินเข้าบัญชีธนาคาร
        หรือรูปแบบการชำระเงินอื่นตามที่ผู้ให้บริการอาจกำหนด

        <br />4.3.2 การชำระค่าบริการล่าช้า
        ในกรณีที่ผู้ใช้บริการไม่ชำระค่าบริการหรือชำระค่าบริการล่าช้า
        ทางผู้ให้บริการขอสงวนสิทธิในการระงับการให้บริการของผู้ใช้บริการชั่วคราวจนกว่าจะมีการชำระค่าบริการครบถ้วน
        ทั้งนี้ผู้ให้บริการไม่มีความรับผิดใดในความเสียหายที่อาจเกิดขึ้นกับผู้ใช้บริการ
        จากการระงับการใช้บริการด้วยเหตุดังกล่าว
        และการระงับการให้บริการดังกล่าวจะไม่มีผลกระทบต่อสิทธิอื่นที่ผู้ให้บริการอาจมีภายใต้กฎหมายที่เกี่ยวข้อง

        <br /><br /><b>5. หน้าที่ของผู้ใช้บริการ และข้อห้ามในการใช้บริการ</b>

        <br /><b>5.1 ความรับผิดชอบต่อข้อมูล</b>
        ผู้ใช้บริการเป็นผู้รับผิดชอบแต่เพียงฝ่ายเดียวต่อข้อมูล เนื้อหา รูปภาพ
        หรือองค์ประกอบใดก็ตามที่ผู้ใช้บริการอาจนำเข้ามาในระบบเพื่อการใช้บริการของผู้ให้บริการ
        (“ข้อมูลของผู้ใช้บริการ”) โดยผู้ใช้บริการต้องรับประกันความถูกต้อง
        ครบถ้วน และสิทธิอันชอบด้วยกฎหมายในการนำส่งข้อมูลของผู้ใช้บริการทั้งหมด
        หากมีข้อโต้แย้ง ใดเกี่ยวข้องติดพันกับข้อมูลของผู้ใช้บริการ
        ที่อาจส่งผลกระทบต่อผู้ให้บริการ
        ผู้ใช้บริการต้องรับประกันรับผิดชอบความเสียหายทั้งหมดที่อาจเกิดขึ้นดังกล่าวเพียงฝ่ายเดียว

        <br /><b>5.2 แจ้งการแก้ไขข้อมูล</b> มีความประสงค์ที่จะโอนบริการ
        ไม่ว่าทั้งหมดหรือบางส่วนให้แก่บริษัทอื่น หรือ บริษัทในกลุ่มบริษัทของ
        SOCIO หรือบุคคลอื่น SOCIO อาจโอนสถานะทางสัญญาภายใต้สัญญาให้บริษัทอื่น
        หรือ บริษัทในกลุ่มบริษัทของ SOCIO หรือบุคคลอื่น
        หลังจากที่ได้แจ้งให้ท่านทราบ และเมื่อท่านสมัครเข้าใช้บริการ
        จะถือว่าท่านได้ให้ความเห็นชอบในการโอนดังกล่าวแล้วเป็นการล่วงหน้า

        <br /><b>5.3 การใช้งานโดยชอบด้วยกฎหมาย</b>
        ผู้ใช้บริการต้องใช้บริการของผู้ให้บริการโดยสุจริต
        และชอบด้วยกฎหมายเท่านั้น ทั้งนี้ผู้ใช้
        บริการจะต้องไม่ใช้บริการของผู้ให้บริการ เพื่อสนับสนุน
        หรือใช้ในทางที่เกี่ยวข้องกับการหรือกิจการใดๆอันมีวัตถุประสงค์ต้องห้ามชัดแจ้งตามกฎหมายไทย
        หรือขัดต่อศีลธรรมอันดี
        หรือที่สร้างความเสื่อมเสียไม่ว่าทางใดต่อผู้ให้บริกร หรือผู้ใช้
        บริการอื่น

        <br />หากมีการตรวจพบการกระทำดังกล่าว
        ผู้ให้บริการขอสงวนสิทธิในการบอกเลิกสัญญา และยกเลิกการให้บริการแก่ผู้ใช้
        บริการดังกล่าว โดยมิต้องแจ้งล่วงหน้า ไม่คืนค่าบริการใดทั้งสิ้น
        และไม่มีความรับผิดต่อความเสียหายใดๆต่อตัวผู้ฝ่าฝืนหรือบุคคลภายนอก
        ทั้งนี้
        ไม่ถือเป็นการตัดสิทธิในการเรียกร้องค่าเสียหายหรือค่าชดเชยใดจากผู้ใช้บริการที่ไม่ได้ปฏิบัติตามข้อ
        จำกัดการใช้บริการดังกล่าว

        <br /><b>5.4 ข้อห้ามในการส่งข้อมูล</b>
        ผู้ใช้บริการจะต้องไม่ส่งข้อมูลใดเข้ามาในระบบของผู้ให้บริการ
        อันเป็นความผิดตามกฎหมายหรืออาจก่อให้เกิดความเสียหายต่อผู้ให้บริการ
        หรือผู้ใช้บริการอื่น ซึ่งรวมถึงแต่ไม่จำกัดเพียง

        <br />5.4.1 ข้อมูลปลอมไม่ว่าทั้งหมดหรือบางส่วน
        หรือข้อมูลอันเป็นเท็จโดยประการที่น่าจะเกิดความเสียหายแก่ผู้อื่น
        หรือประชาชน รวมถึงข้อมูลหรือเนื้อหาใดก็ตามที่ผิดกฎหมายหรือศีลธรรมอันดี
        หมิ่นประมาท หยาบคาย อนาจาร เหยียดผิว เชื้อชาติ ศาสนา
        และ/หรือละเมิดสิทธิส่วนบุคคลของผู้อื่น ใช้ถ้อยคำยั่วยุ รุนแรง
        มีเจตนาแฝงอย่างอื่นนอกจากการโฆษณาประชาสัมพันธ์ โจมตีคู่แข่ง
        หรือพัวพันกับกิจกรรมใดที่ผิดกฎหมายไม่ว่าในลักษณะใด

        <br />5.4.2 ข้อมูลอันเป็นเท็จ
        โดยประการที่น่าจะเกิดความเสียหายต่อความมั่นคงของประเทศหรือก่อให้เกิดความตื่นตระหนกแก่ประชาชน
        หรือข้อมูลอันเป็นความผิดเกี่ยวกับความมั่นคงแห่งราชอาณาจักร
        หรือความผิดเกี่ยวกับการก่อการร้ายตามประมวลกฎหมายอาญา

        <br />5.4.3 ข้อมูลคอมพิวเตอร์ใดๆ
        ที่มีลักษณะอันลามกและข้อมูลคอมพิวเตอร์นั้นประชาชนทั่ว ไปอาจเข้าถึงได้

        <br />5.4.4 ข้อความเนื้อหาใดก็ตาม ที่มีไวรัสคอมพิวเตอร์ ชุดคำสั่ง
        หรือโค๊ดคอมพิวเตอร์ ไฟล์หรือโปรแกรมอื่นที่ออกแบบไว้เพื่อทำลาย
        ทำให้การทำงานของคอมพิวเตอร์ฮาร์ดแวร์ หรือซอฟแวร์หยุดชะงัก
        หรือจำกัดฟังก์ชั่นการทำงานหรือสร้างอันตรายต่อความปลอดภัยของระบบคอมพิวเตอร์
        หรือระบบเน็ตเวิร์ค อุปกรณ์ ฮาร์ดแวร์ หรือ ซอฟท์แวร์ของผู้ให้บริการ
        หรือของบุคคลภายนอกที่ผู้ให้บริการได้ใช้ประกอบการให้บริการ
        หรือของผู้ใช้บริการอื่น ข้อความนี้ให้รวมถึง การส่งหรือป้อนข้อมูลไวรัส
        Trojan, Worm

        <br />5.4.5 ข้อมูลที่อาจทำให้เชื่อว่าเป็นบุคคลอื่น
        หรือหลอกลวงตัวตนของผู้ใช้บริการดังกล่าว ซึ่งอาจนำไปสู่ความเสียหายต่อ
        เจ้าของข้อมูลจริง ผู้ให้บริการ หรือบุคคลอื่นใด

        <br />5.4.6 ข้อมูลเอกสารใดที่ละเมิดต่อกฎหมาย หรือ
        ที่ผู้ใช้บริการไม่มีสิทธิจะใช้ตามกฎหมาย
        รวมถึงข้อมูลอื่นใดที่อาจสร้างผลกระทบความเสียหายต่อผู้ให้บริการหรือบุคคลอื่นใด
        ซึ่งรวมถึงแต่ไม่จำกัดเพียงข้อความเนื้อหาใดก็ตามที่ละเมิดลิขสิทธิ์
        สิทธิบัตร เครื่องหมายการค้า เครื่องหมายบริการ ความลับทางการค้า และ
        สิทธิในทรัพย์สินทางปัญญาอื่นใดอันอาจและ/หรือ
        เล็งเห็นผลได้ว่าเป็นการละเมิดสิทธิของผู้อื่น

        <br /><b>5.5 ข้อห้ามในการใช้บริการ</b>
        ผู้ใช้บริการตกลงที่จะไม่ใช้บริการในลักษณะใด ๆ ดังต่อไปนี้

        <br />5.5.1 ปลอมแปลงหรือลอกเลียนผู้อื่น
        หรือกระทำการใดที่เป็นการแสดงแก่บุคคลภายนอกว่า เป็นเจ้าหน้าที่ พนักงาน
        ตัวแทน ผู้รับมอบอำนาจของผู้ให้บริการ
        ในการแสวงหาผลประโยชน์อันมิชอบและทำให้บุคคลโดยทั่วไปเข้าใจได้ว่ากระทำการแทนผู้ให้บริการ

        <br />5.5.2 ปลอมแปลงลอกเลียน ตัดทอน แก้ไข เปลี่ยนแปลงหรือกระทำการใด
        ซึ่งมีผลทำให้ข้อความใดในระบบหรือเว็บไซต์ให้เปลี่ยนแปลงไปจากเดิม

        <br />5.5.3 ใช้โปรแกรมดึงข้อมูลต่าง ๆ ภายในแพลตฟอร์ม (screen scraping)
        การทําเหมืองข้อมูล (Data Mining) โรบอต (robots) หรือ
        เทคนิคการรวบรวมข้อมูล (data gathering) ในลักษณะเช่นเดียวกัน
        และเครื่องมือที่ใช้ในการดึงข้อมูลบนเว็บไซต์ เพื่อสร้าง ดำรงไว้ พัฒนาต่อ
        หรือถอดแบบข้อมูลที่มีอยู่ในเว็บไซต์ หรือในเอกสารสิ่งพิมพ์อื่นใด
        ยกเว้นจะได้ความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากบริษัท

        <br />5.5.4 พยายามหรือกระทำการใด
        เพื่อเข้าถึงส่วนใดของเว็บไซต์โดยปราศจากอำนาจในการกระทำเช่นว่า
        เว้นแต่ผู้ให้ บริการได้อนุญาตโดยชัดแจ้งเป็นลายลักษณ์อักษรให้ทำได้

        <br /><b>5.6 การชดเชยกรณีผู้ใช้บริการละเมิดหน้าที่</b>
        ในกรณีที่ผู้ใช้บริการไม่ปฏิบัติตามหน้าที่โดยเฉพาะข้อห้ามการใช้ข้อมูล
        หรือข้อห้ามในการใช้บริการหรือเงื่อนไขอื่น
        ซึ่งอาจนำไปสู่การเรียกร้องความเสียหายไม่ว่ากรณีใดต่อผู้ให้บริการ
        ผู้ใช้บริการที่ละเมิดหน้าที่ดังกล่าวมีหน้าที่ในการแก้ไขความ
        บกพร่องและข้อร้องเรียนดังกล่าวโดยฝ่ายเดียว
        และต้องชดเชยและรับประกันป้องกันความเสียหายใดที่อาจเกิดขึ้นกับผู้ให้บริการจากการละเมิดของผู้ใช้บริการดังกล่าวทั้งหมด
        โดยไม่ถือเป็นการจำกัดสิทธิในการเรียกร้องอื่นของผู้ให้บริการ
        ภายใต้กฎหมายที่เกี่ยวข้อง ซึ่งรวมถึงการเรียกร้องค่าเสียหายหรือค่าใช้
        จ่าย ไม่ว่าโดยตรงหรือโดยอ้อม (รวมทั้งค่าทนายความ) จากเหตุดังกล่าว

        <br /><b>5.7 สิทธิในการระงับหรือบอกเลิกสัญญาโดยผู้ให้บริการ</b>
        ในกรณีมีการดำเนินการผิดหน้าที่ใดๆตามที่ระบุไว้ที่ผู้ใช้บริการอาจดำเนินการ
        ผู้ให้บริการมีสิทธิเด็ดขาดในการระงับการให้บริการชั่วคราว
        หรือมีสิทธิบอกเลิกสัญญาโดยไม่ต้องแจ้งให้ทราบล่วงหน้าและไม่ต้องคืนค่าบริการใดๆทั้งสิ้น

        <br /><br /><b>6. การเลิกสัญญา</b>

        <br /><b>6.1 สิทธิในการเลิกสัญญาโดยผู้ใช้บริการ</b>

        <br />6.1.1 กรณีเลิกสัญญาสำหรับแพ็กเกจที่ไม่มีค่าบริการ
        ผู้ใช้บริการมีสิทธิเลิกสัญญากรณีผู้ใช้บริการใช้บริการแพ็กเกจฟรีได้โดยการส่งคำขอเลิกใช้บริการมายัง
        support@socio.co.th

        <br />6.1.2 กรณีเลิกสัญญาสำหรับแพ็กเกจที่มีค่าบริการรายปี
        ผู้ใช้บริการมีสิทธิเลิกสัญญาได้โดยการส่งคำขอเลิกใช้บริการมายัง
        support@socio.co.th แต่ทั้งนี้
        การยื่นคำขอดังกล่าวจะทำในเวลาใดก็ได้ก่อนสิ้นสุดระยะเวลาการใช้บริการ
        โดยมีผลเป็นการเลิกสัญญาในวันสุดท้ายของระยะเวลาการใช้บริการในปีที่ได้ยื่นคำขอเลิกการใช้บริการดังกล่าว
        ในกรณีที่ผู้ใช้บริการมีความประสงค์จะเลิกสัญญาโดยให้มีผลในทันที (ปิด
        Account ก่อนสิ้นสุดระยะเวลาการใช้บริการ)
        ผู้ให้บริการสงวนสิทธิที่จะไม่คืนเงินค่าบริการที่ผู้ใช้บริการได้ชำระไว้แล้วไม่ว่ากรณีใดๆ

        <br />6.2 สิทธิในการเลิกสัญญาโดยผู้ให้บริการ

        <br />6.2.1 ผู้ให้บริการ มีสิทธิบอกเลิกสัญญาไม่ว่าเวลาใดๆ
        ไม่ว่าจะมีเหตุจำเป็นหรือเหตุสุดวิสัยได้
        เมื่อผู้ให้บริการทำการบอกเลิกสัญญาแล้วให้ถือว่า การเลิกสัญญา
        มีผลในวันสุดท้ายของระยะเวลาการใช้บริการในปีที่ได้ทำการบอกเลิกสัญญา

        <br />6.2.2
        ผู้ให้บริการมีสิทธิในการบอกเลิกสัญญาได้ในกรณีที่ผู้ใช้บริการปฏิบัติผิดหน้าที่ไม่ว่าในลักษณะใด
        ตามที่กำหนดไว้ในเงื่อนไขฉบับนี้

        <br /><br /><b>7. การโอนสิทธิ</b>

        <br /><b>7.1 การโอนสิทธิโดยผู้ใช้บริการ</b>
        ผู้ให้บริการไม่อนุญาตให้ผู้ใช้บริการโอนสิทธิและหน้าที่ที่เกิดขึ้นที่เกิดขึ้นภายใต้สัญญา
        ให้แก่บุคคลอื่นโดยมิได้รับความยินยอมเป็นลายลักษณ์อักษรจากผู้ให้บริการก่อน

        <br /><b>7.2 การโอนสิทธิโดยผู้ให้บริการ</b> ในกรณีที่ ผู้ให้บริการ
        มีความประสงค์ที่จะโอนบริการ ไม่ว่าทั้งหมดหรือบางส่วนให้แก่บริษัทอื่น
        หรือบริษัทในกลุ่ม หรือบุคคลอื่น
        ผู้ให้บริการอาจโอนสถานะทางสัญญาภายใต้สัญญาให้บริษัทอื่น
        หลังจากที่ได้แจ้งให้ผู้ใช้ บริการทราบ
        และเมื่อผู้ใช้บริการสมัครเข้าใช้บริการ
        จะถือว่าผู้ใช้บริการได้ให้ความเห็นชอบในการโอนดังกล่าวแล้วเป็นการล่วงหน้า

        <br /><br /><b>8. ความลับ และทรัพย์สินทางปัญญา</b>

        <br /><b>8.1 การรักษาความลับ</b>
        คู่สัญญาตกลงที่จะไม่เปิดเผยความลับของคู่สัญญาอีกฝ่ายหนึ่ง
        ให้แก่บุคคลภายนอกหรือองค์กรอื่นใดไม่ว่าเพื่อวัตถุประสงค์ใดๆ เว้นแต่ (1)
        ได้รับความยินยอมจากเจ้าของข้อมูลความลับดังกล่าว หรือ (2)
        เป็นกรณีที่กฎหมายบังคับ เช่น การทำตามคำสั่งศาล หรือหมายศาล เป็นต้น

        <br /><b>8.2 ทรัพย์สินทางปัญญาของผู้ให้บริการ</b>
        ผู้ให้บริการเป็นเจ้าของลิขสิทธิ์ และ ทรัพย์สินทางปัญญาในข้อมูลที่แสดง
        รูปภาพ และ รูปแบบการแสดงผล ตามที่ปรากฏในเว็บไซต์ทั้งหมด
        ยกเว้นจะมีการระบุอย่างชัดเจนเป็นอื่น
        และสิทธิในทรัพย์สินทางปัญญาดังกล่าวอยู่ภายใต้เงื่อนไข ดังนี้

        <br />8.2.1 ข้อห้าม ห้ามมิให้ผู้ใดทำการคัดลอก ทำซ้ำ มีสำเนา สำรองไว้
        ทำเลียนแบบ ทำเหมือน ดัดแปลง ทำเพิ่ม
        เพื่อนำไปเผยแพร่ด้วยวัตถุประสงค์อื่นใด
        โดยปราศจากความยินยอมเป็นหนังสือจากผู้ให้บริการ
        ทั้งนี้เว้นแต่จะได้มีการระบุกำหนดไว้ในข้อตกลงและเงื่อนไขในเว็บไซต์s

        <br />8.2.2 การอนุญาตให้เข้าถึงข้อมูลหรือเอกสาร ข้อมูล
        หรือเอกสารอิเลกทรอนิกส์ใน Account (บัญชีผู้ใช้งาน)
        ของผู้ใช้บริการถือเป็นทรัพย์สินของผู้ใช้บริการ อย่าง
        ไรก็ตามการเข้าถึงข้อมูล
        หรือเอกสารอิเลกทรอนิกส์ดังกล่าวจะต้องอยู่ในเงื่อนไขที่ว่า
        ผู้ใช้บริการได้ชำระค่าบริการรายปีโดยสิ้นเชิงแล้ว และถือว่า
        ผู้ใช้บริการได้อนุญาตให้ผู้ให้บริการใช้ คัดลอก ส่ง จัดเก็บ หรือ
        ทำการสำรอง(Backup) ข้อมูล
        หรือเอกสารอิเลกทรอนิกส์ดังกล่าวเพื่อวัตถุประสงค์ในการให้บริการของผู้ให้บริการเพื่อประโยชน์ของผู้ใช้บริการในการเข้าถึงข้อมูลต่างๆด้วยความสะดวกรวดเร็วเท่านั้น

        <br />ทั้งนี้เพื่อหลีกเลี่ยงข้อสงสัย
        ผู้ให้บริการไม่สามารถให้การรับรองได้ว่า
        ข้อมูลของผู้ใช้บริการจะไม่มีทางสูญหายหรือเสียหายไม่ว่าประการใดๆ
        ซึ่งอาจเกิดขึ้นได้ด้วยเหตุสุดวิสัยหรือด้วยเหตุปัจจัยที่ไม่อาจคาดถึงได้
        ดังนั้น
        ผู้ใช้บริการมีหน้าที่ที่จะต้องสำรองข้อมูลหรือทำการคัดลอกข้อมูลหรือเอกสารใดๆที่ผู้ใช้บริการต้องการส่งเข้ามาในระบบด้วยตัวผู้ใช้บริการเองอีกทางหนึ่ง
        เพื่อประโยชน์สูงสุดในการเก็บรักษาข้อมูลของผู้ใช้บริการ
        โดยผู้ให้บริการจะไม่รับผิดใดๆ ในกรณีข้อมูลสูญหายหรือเสียหาย

        <br /><b>8.3 การใช้ทรัพย์สินทางปัญญาของผู้ใช้บริการ</b>
        ผู้ใช้บริการอนุญาตให้ผู้ให้บริการ และหรือบริษัทในเครือนำชื่อกิจการ
        ชื่อบริษัท และ/หรือโลโก้ของกิจการของผู้ใช้บริการ
        มาอยู่บนสิ่งตีพิมพ์หรือสื่อใดๆหรือเผยแพร่ด้วยวิธีอื่นใดเพื่อวัตถุประสงค์ในการอ้างอิงลูกค้าผู้ใช้บริการของผู้ให้บริการ

        <br /><br /><b>9. การระงับการให้บริการ และการยกเลิกการให้บริการ</b>

        <br /><b>9.1 การระงับการให้บริการ</b> ผู้ให้บริการ
        อาจระงับการให้บริการเป็นการชั่วคราว ด้วยเหตุที่ระบุไว้ในข้อ 9.1
        นี้และในกรณีที่มีการระงับบริการแพ็กเกจประเภทที่มีค่าบริการ
        ผู้ให้บริการขอสงวนสิทธิในการไม่คืนค่าบริการสำหรับระยะเวลาที่ระงับบริการดังกล่าว
        และถือว่า ผู้ให้บริการ
        ไม่มีความรับผิดในการระงับบริการดังกล่าวไม่ว่าประการใด

        <br />9.1.1 การบำรุงรักษาอุปกรณ์ที่จำเป็นสำหรับการให้บริการ

        <br />9.1.2 อุปกรณ์ที่จำเป็นในการให้บริการไม่สามารถใช้งานได้

        <br />9.1.3
        เกิดเหตุในด้านการปฏิบัติการหรือด้านเทคนิคซึ่งส่งผลต่อการให้บริการ

        <br />9.1.5 การเกิดเหตุการณ์ที่เป็นอุปสรรคในการให้บริการ
        โดยเฉพาะเหตุสุดวิสัยใด

        <br />9.1.6 ผู้ใช้บริการไม่ปฏิบัติตามเงื่อนไขฉบับนี้

        <br />9.2 การยกเลิกการให้บริการ ผู้ให้บริการ
        ขอสงวนสิทธิในการยกเลิกการให้บริการทั้งหมดหรือแต่บางส่วน
        โดยไม่ต้องแจ้งล่วงหน้า และไม่มีความรับผิดใดต่อคู่สัญญาและบุคคลภายนอก

        <br /><br /><b>10. ข้อจำกัดความรับผิด</b>

        <br /><b>10.1 ข้อจำกัดความรับผิดทั่วไป</b>
        แม้ผู้ให้บริการจะมีความตั้งใจและพยายามอย่างยิ่งในการให้บริการอย่างเต็มประสิทธิภาพ
        แต่อย่างไรก็ตาม
        การให้บริการของผู้ให้บริการเป็นไปในลักษณะตามที่มีตามที่เป็น (As-Is)
        ผู้ให้บริการจึงไม่สามารถให้การรับประกันได้ว่า
        การใช้บริการของผู้ใช้บริการจะปราศจากข้อบกพร่อง
        (ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะความเสถียร, ความเชื่อ ถือได้, ความแม่นยำ,
        ความสมบูรณ์, ประสิทธิผล, ความเหมาะสมกับวัตถุประสงค์บางประการ,
        ความบกพร่องเกี่ยวกับความปลอดภัย, ข้อผิดพลาด, จุดบกพร่องของคอมพิวเตอร์
        (bug) หรือ ไวรัส (virus))

        <br /><b>10.2 ข้อจำกัดความเสียหาย</b>
        ผู้ให้บริการไม่รับผิดชอบต่อความเสียหายใดที่เกิดจากการใช้บริการ
        หรือที่เกิดขึ้นจากความบกพร่องในการใช้บริการไม่ว่าด้วยเหตุใด
        เว้นแต่ความเสียหายเกิดจากการจงใจหรือความประมาทประมาทเลินเล่ออย่างร้ายแรงของ
        ผู้ให้บริการ ทั้งนี้ ในกรณีดังกล่าว ผู้ให้บริการ
        จำกัดความรับผิดต่อค่าเสียหายที่แท้จริงที่เกิดขึ้นแก่ผู้ใช้บริการไม่ว่าโดยตรงหรือโดยอ้อมสุงสุดไม่เกินอัตราค่าบริการรายปีหรือตามอัตราที่กำหนด
        ที่ผู้ให้บริการได้รับชำระจากผู้ใช้บริการ
        ในปีที่เกิดเหตุการณ์ซึ่งทำให้เกิดค่าเสียหายนั้น โดยเฉพาะอย่างยิ่ง
        ผู้ให้บริการไม่รับผิดต่อการสูญหายของผลกำไร Goodwill การใช้ประโยชน์
        ข้อมูล
        หรือความเสียหายที่ไม่เป็นรูปธรรมอื่นของการใช้บริการซึ่งไม่ใช่ความเสียหายโดยตรง

        <br />
        <br /><b>11. ข้อกำหนดอื่น</b>

        <br /><b>11.1 การเป็นโมฆะบางส่วน</b>
        หากมีข้อกำหนดหรือข้อความใดในเงื่อนไขฉบับนี้เป็นโมฆะ
        หรือใช้บังคับไม่ได้ตามกฎหมาย
        ให้ถือว่าข้อกำหนดหรือข้อความที่ตกเป็นโมฆะหรือใช้บังคับไม่ได้นั้นไม่มีผลกระทบต่อความสมบูรณ์หรือการบังคับใช้ข้อกำหนดอื่น

        <br /><b>11.2 ภาษา </b>
        ในกรณีที่มีความแตกต่างระหว่างเงื่อนไขฉบับนี้ที่เป็นฉบับภาษาไทยและฉบับคำแปล
        เงื่อนไขฉบับภาษาไทยจะมีผลเหนือเงื่อนไขฉบับภาษาอื่น

        <br /><b>11.3 กฎหมายที่ใช้บังคับและการแก้ไขข้อพิพาท</b> เงื่อนไขฉบับนี้
        อยู่ภายใต้บังคับของกฎหมายไทย
        และให้ศาลในราชอาณาจักรไทยเป็นศาลที่มีเขตอำนาจในการพิจารณาชี้ขาดตัดสินข้อพิพาทที่เกิดขึ้นภายใต้เงื่อนไขการใช้บริการฉบับนี้

        <br /><b>11.4 การแก้ไขเงื่อนไข</b> ผู้ให้บริการ
        ขอสงวนสิทธิในการเปลี่ยนแปลงเงื่อนไขฉบับนี้ไม่ว่าเวลาใด
        โดยถือว่าการเปลี่ยนแปลงมีผลทันทีเมื่อทำการแก้ไขบนหน้าเว็บไซต์

        <br /><b>11.5 การติดต่อสื่อสาร</b>
        ผู้ใช้บริการยืนยันว่าจดหมายอิเล็กทรอนิกส์ตามที่ผู้ใช้บริการได้ระบุไว้ในส่วนหนึ่งส่วนใดของเว็บไซต์
        เป็นจดหมายอิเล็กทรอนิกส์ dpo@socio.co.th  ที่สามารถเข้าถึงผู้ใช้บริการได้ตลอดเวลา
        ผู้ใช้บริการรับทราบว่า การติดต่อ จดหมายและค่าประกาศใดจากผู้ให้บริการ
        ไปยังผู้ใช้บริการทางจดหมายอิเล็กทรอนิกส์ dpo@socio.co.th 
        ตามที่ผู้ใช้บริการให้ถือว่าเป็นการแจ้งให้แก่ผู้ใช้บริการทราบด้วยวิธีที่ถูกต้อง
        และให้ถือว่าผู้รับได้เห็นการติดต่อดังกล่าวแล้วตั้งแต่วันที่ส่งการติดต่อดังกล่าวทางจดหมายอิเล็กทรอนิกส์
        dpo@socio.co.th นั้น

        <br />ประกาศเมื่อวันที่ 30 พฤษภาคม 2565
        <b-col cols="12"
          ><br />
          <b-form-group>
            <b-form-checkbox
              id="checkbox-9"
              name="checkbox-9"
              value="0"
              v-model="Remember_me"
            >
              ข้าพเจ้ายอมรับข้อกำหนดการใช้บริการและรับทราบถึง
              <a href="https://socio.co.th/privacy-statement/" target="_blank"
                >นโยบายความเป็นส่วนตัว</a
              >
            </b-form-checkbox>
            <b-alert v-if="check.Remember_me" variant="danger" show>
              <span>กรุณายอมรับเงื่อนไขการใช้บริการ!</span>
            </b-alert>
          </b-form-group>
        </b-col>
      </div>
      <b-button @click="save()" variant="relief-primary">
        ยอมรับและดำเนินการต่อ
      </b-button>
    </b-card>
  </div>
</template>


<script>
import {
  BCard,
  BCardHeader,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BBadge,
  BCardText,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BInputGroup,
  BFormSelect,
  BAlert,
  BFormFile,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";

import VueSlider from "vue-slider-component";
import store from "@/store/index";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BFormSelect,
    BAlert,
    BFormFile,
    BImg,
    VueSlider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      Remember_me: null,
      check: {
        Remember_me: false,
      },
    };
  },
  methods: {
    save() {
      if (this.Remember_me) {
        const params = {
          Remember_me: "ยอมรับและยินยอม",
          store_id: localStorage.getItem("store_id"),
        };
        api
          .post("update_term_of_use_stroe", params, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("response.data :>> ", response.data);
            this.$swal({
              icon: "success",
              title: "success",
              showConfirmButton: false,
              timer: 3000,
            });
            this.$router.push({ name: "index" });
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: " เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      } else {
        this.check.Remember_me = true;
        this.$swal({
          title: "Error!",
          text: " กรุณายอมรับเงื่อนไขการใช้บริการ!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#body {
  font-size: 12px;
}
b {
  margin-bottom: 10px;
}
</style>